import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCloud, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faJenkins } from '@fortawesome/free-brands-svg-icons'

function LinkBtn({ name, icon, dest }){
    return (
        <a class="linkBtn" href={dest}>
            <h3>
             <FontAwesomeIcon icon={icon} />
             &nbsp;
             {name} 
            </h3>
        </a>
    );
}

function LinkIcon({ icon, dest }){
    return (
        <a class="linkIcn" href={dest}>
            <FontAwesomeIcon icon={icon}/>
        </a>
    );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>
          Hi, I'm Johnathan!
        </h3>
        <div class="buttonGroup">
            <LinkBtn name="Resume" icon={faDownload} dest="https://www.johnnyw.ca/nextcloud/s/D4Hmqf7axDBpKn8"/>
            <LinkBtn name="Nextcloud Server" icon={faCloud} dest="https://www.johnnyw.ca/nextcloud"/>
            <LinkBtn name="Jenkins Server" icon={faJenkins}  dest="https://www.johnnyw.ca/jenkins"/>
        </div>
        <div class="bottomBar">
            <LinkIcon icon={faGithub} dest="https://github.com/johnnyw3"/>
            <LinkIcon icon={faEnvelope} dest="mailto:johnnyw@johnnyw.ca"/>
        </div>
      </header>
    </div>
  );
}

export default App;
